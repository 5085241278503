import React from 'react'
import { withPreviewResolver } from 'gatsby-source-prismic'
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner'

const Preview = ({ isPreview }) => {
  if (isPreview === false) return 'Not a preview!'

  return (
    <div className="h-64 flex items-center justify-center">
      <LoadingSpinner />
    </div>
  )
}

export default withPreviewResolver(Preview, {
  repositoryName: 'cabuya-handmade',
  linkResolver: () => doc => {
    const { type, uid, link_type } = doc

    if (link_type && link_type === 'Document') return '/'

    switch (type) {
      case 'homepage':
        return '/'
      case 'product':
        const {
          data: {
            category: { uid: categoryUid = '' },
            subcategory: { uid: subcategoryUid = '' },
          },
        } = doc
        return `/${categoryUid}/${subcategoryUid}/${uid}`
      case 'subcategory': {
        const {
          data: {
            parent_category: { uid: categoryUid },
          },
        } = doc
        return `/${categoryUid}/${uid}`
      }
      default:
        return `/${uid}`
    }
  },
})
